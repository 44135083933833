import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from '@reach/router';
import styles from './arrowBack.module.scss';

const ArrowBack = ({ navigationTo, text, className }) => {
  const intl = useIntl();
  const textContent = text || intl.formatMessage({ id: 'backToPlans' });

  return (
    <Link className={clsx(styles.link, className)} to={navigationTo}>
      <i className="icon-arrow-left" />
      {textContent}
    </Link>
  );
};

ArrowBack.defaultProps = { text: '', className: '' };

ArrowBack.propTypes = {
  navigationTo: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default ArrowBack;
